import React from "react";
import Particle from "../Particles";
import Icons from "../icons/icons.component.jsx"
import "./home-page.styles.scss";
import Home2 from "./Home2";

class HomePage extends React.Component{
    render(){
        return(
            <div className="Home-Div">
                <Particle />
                {/* <Particle /> */}
                {/* <Projects /> */}
                <div className="headerImage">
                    <h1 className="headerText">Welcome to my website.</h1>
                    <h1 className="headerText">My name is George, I'm a Software Developer.</h1>
                    <Icons />
                </div>
                <Home2 />
            </div>
        );
    }
}
export default HomePage;