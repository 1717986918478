import React, { useEffect, useState } from "react";
import "./projects.styles.scss";
import ProjectBlock from "../projectBlock/projectBlock.component.jsx";
import { useParams } from "react-router-dom";
import { getProjects } from "../../services/projectServices";
import Particle from "../Particles";
import { Spinner } from "react-bootstrap";

function Projects(){
    const [projects, setProjects] = useState(null)
    const [paragraphs, setParagraphs] = useState(null)

    useEffect(()=> {
        getProjects().then(data => {
            console.log(data);
            setProjects(data.projects);
            setParagraphs(data.paragraphs);
        }).catch(err => {

        });
    },[])

        return(
            <div className="projects">
                <Particle />
                <div className="margin-bottom-50-px">
                    <h1>My projects</h1>
                    <p className="bold">Throughout the years of my studies, I was able to finish some cool projects with my colleagues and some of my own. Some of my projects: </p>
                </div>
    
                {projects?.length > 0 ?
                <div className="projects-display">
                    {
                            projects?.map(({ id, header, content, githubLink }) => (
                                <ProjectBlock key={id} id={id} header={header} content={content} link={githubLink} paragraphs={paragraphs} />
                            )) 
                    }
                    </div> : <span>Trying to load projects ...<Spinner /></span>}
   
            </div>
        );

}
export default Projects;