import axios from "axios";
import appConstants from "../constants/app.constants";
import { projectsServicesConstants } from "../constants/services.constants";

function insertProject(projectObj){
    return new Promise((resolve, reject)=>{
        try{
            axios.post(`${appConstants.BASE_URL}/${projectsServicesConstants.INSERT_PROJECT}`, projectObj).then(data => {
                console.log("data stored ", data);
                resolve(data);
            }).catch(err => {
                console.log("someting went wrong ", err);
                reject(err);
            })
        }
        catch(err){
            reject(err);
        }
    })
}

function getProjects(){
    return new Promise( async (resolve1, reject1) => {
        try {
            //console.log(appConstants.BASE_URL);
            axios.get(`${appConstants.BASE_URL}/${projectsServicesConstants.GET_PROJECTS}`).then(data => {
                //console.log(data.data);
                resolve1(data.data);
            })
            .catch(err => {
                console.log("someting went wrong ", err);
                reject1(err);
            })
        }
        catch (err) {
            reject1(err);
        }
    })
}

export {
    insertProject,
    getProjects,
}