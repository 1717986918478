import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { myProjects } from "../../constants/constants";
import { withRouter, useParams, useRouteMatch, matchPath } from "react-router-dom";
import { getProjects } from "../../services/projectServices";
import Particle from "../Particles";

import "./project-page.styles.scss";

// let InitialState = {
//         projectId: "",
//         element : myProjects[0]

// }

function ProjectPage(){

    const [project, setProject] = useState(null)
    const [paragraphes, setParagraphes] = useState(null)
    const {id} = useParams()

   useEffect(()=> {
       getProjects().then(data => {
           //console.log(typeof data.projects['0'].id ,typeof this.state.id);
           let project = data.projects.filter(item => item.id.toString() === id).map(ite => ite)[0];
           //console.log(project);
           let newParagraphsArray = data.paragraphes.filter(item => item.parent_id.toString() === id).map(it => it);
           setProject(project ?? {})
           setParagraphes(newParagraphsArray ?? []);
           //console.log(this.state);
       }).catch(err => {

       });
   },[])


        return(
            <>
            <Particle />
            {project ? 
                <div key={id} className="project-view">
                    <a href={project?.githubLink}>
                        <FontAwesomeIcon className="font-awesome-icon" icon={faGithub} />
                    </a> 
                    <h1>{project?.header}</h1>
                    <p>{project?.content}</p>
                    <div key={id+"paragraphes"}>{paragraphes?.map(({ head, text }, index) => (
                        <div key={id+ index + "paragraphes-child"}>
                            <h3>{head}</h3>
                            <p>{text}</p>
                        </div>
                    ))}
                    </div>
                </div> :null
            }
            </>

        );
    
}
export default ProjectPage;