import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
    AiFillGithub,
    AiOutlineTwitter,
    // AiFillInstagram,
    AiFillMail
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Footer() {
    let date = new Date();
    let year = date.getFullYear();
    return (
        <div className="footer">
            <div className="footer-copywright">
                <h3>Developed by George Abdulaziz</h3>
            </div>
            <div className="footer-copywright">
                <h3>Copyright © {year} </h3>
            </div>
            <div className="footer-body">
                <ul className="footer-icons">
                    <li className="social-icons">
                        <a
                            href="https://github.com/georgeabdulaziz"
                            style={{ color: "#8f51d6" }}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <AiFillGithub />
                        </a>
                    </li>
                    <li className="social-icons">
                        <a
                            href="https://www.linkedin.com/in/george-abdulaziz/"
                            style={{ color: "#8f51d6" }}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <AiOutlineTwitter />
                        </a>
                    </li>
                    <li className="social-icons">
                        <a
                            href="https://twitter.com/AbdulazizGeorge"
                            style={{ color: "#8f51d6" }}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FaLinkedinIn />
                        </a>
                    </li>
                    <li className="social-icons">
                        <a
                            href="mailto:georgeabdulaziz1@gmail.com"
                            style={{ color: "#8f51d6" }}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {/* <AiFillInstagram /> */}
                            <AiFillMail />
                        </a>
                    </li>
                </ul>
            </div>

        </div>
    );
}

export default Footer;
