import React, {useState} from "react";
import { insertProject } from "../../services/projectServices";


function ProjectInput(props){
    const [header, setHeader] = useState("");
    const [content, setConent] = useState("");
    const [githubLink, setGithubLink] = useState("");
    const [headerIntro, setHeaderIntro] = useState("");
    const [paragraphes, setParagraphes] = useState([]);

    const handleSubmit = ()=> {
        let projectObj = {
            header : header,
            content : content,
            githubLink : githubLink,
            headerIntro: headerIntro,
            paragraphes: paragraphes
        }
        console.log(projectObj);
        insertProject(projectObj).then(data => {
            console.log("inserted successfully ", data);
        });
    }

    const addParagraphsHandler = ()=> {
        let tag = document.getElementById("paragraphs");
        let lastIndex = paragraphes.length;
        let elm = {head : "", text: ""}
        let newPara = paragraphes;
        newPara.push(elm);
        setParagraphes(newPara)
        const child = document.createElement('div');
        child.classList.add("margin-top-1-rem")

        const head = document.createElement("input");
        head.type = "text";
        head.value = paragraphes[lastIndex].head;
        head.onchange = (e) => {elm["head"] = e.currentTarget.value };
        head.placeholder = lastIndex + " head"

        const tex = document.createElement("textarea");
        // tex.type = "text";
        tex.value = paragraphes[lastIndex].text;
        tex.onchange = (e) => { elm["text"] = e.currentTarget.value };
        tex.placeholder = lastIndex + " text"

        child.appendChild(head);
        child.appendChild(tex);
        // child.appendChild(
        //     <input type="text" value={githubLink} onChange={(e) => {elm["head"] = e.currentTarget.value }} />);
        // child.appendChild(
        //     <input type="text" value={headerIntro} onChange={(e) => { elm["text"] = e.currentTarget.value }} />);

        tag.appendChild(child);
    }

    return (
        <div>
            <p>hello</p>
            <input placeholder={`header`} type="text" value={header} onChange={(e)=> setHeader(e.currentTarget.value)} />
            <input placeholder={`content`} type="text" value={content} onChange={(e) => setConent(e.currentTarget.value)} />
            <input placeholder={`githubLink`} type="text" value={githubLink} onChange={(e) => setGithubLink(e.currentTarget.value)} />
            <input placeholder={`headerIntro`} type="text" value={headerIntro} onChange={(e) => setHeaderIntro(e.currentTarget.value)} />
            <div id={`paragraphs`}>
                <button onClick={()=> addParagraphsHandler()}>add paragraphs</button>
            </div>
            <button onClick={()=> handleSubmit()}>submit</button>
        </div>
    );

}

export default ProjectInput;