import React, { useState } from "react";
import "./projectBlock.styles.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';

import { useNavigate } from "react-router-dom";
// import Particle from "../Particles";


function ProjectBlock({id,header,content,link,paragraphs}) {
    const Navigate = useNavigate()
    return (
        <div className="projectBlock">
            {/* <Particle /> */}
            <h2>{header}</h2>
            <p>{content}</p>
            {/* <a href={this.props.link}>
                    {"Read More "}
                    <FontAwesomeIcon icon={faArrowCircleRight} />
                </a> */}
            <div key={id} className="clickable" onClick={() => Navigate(`/project/${id}`)}>
                {"Read More "}
                <FontAwesomeIcon className="clickable" icon={faArrowCircleRight} />
            </div>
        </div>
    );
}

export default ProjectBlock;