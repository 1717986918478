import logo from './logo.svg';
import './App.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './views/home-page/home-page.components';
import NavBar from './views/navbar/Navbar';
import ProjectPage from './views/project-page/project-page.components';
// import React, { Suspense, lazy } from 'react';
import ProjectInput from './views/projectInput-page/projectInput';
import { Suspense } from 'react';
import Projects from './views/projects/projects.components.jsx';
import About from "./views/About/About";
import Footer from './views/Footer';

// const Home = lazy(()=>{ import('./views/home-page/home-page.components') });

function App() {
  return (  

    <BrowserRouter>
    <div className='App'>
      <NavBar />
      <Suspense fallback={<div>Loading ...</div>}>
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route exact path="/projects" element={<Projects />} />
              <Route exact path="/about" element={<About />} />
              <Route exact path="/project/:id" element={<ProjectPage />} />
              <Route exact path="/insert" element={<ProjectInput />} />
            </Routes>
 
      </Suspense>
      <Footer />
    
    </div>
    </BrowserRouter>


      

  );
}

export default App;
