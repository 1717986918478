import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faTwitter, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

class Icons extends React.Component{
    render(){
        return(
            <div className="icons">
                <a href="https://github.com/georgeabdulaziz">   
                    <FontAwesomeIcon className="font-awesome-icon" icon={faGithub} />
                </a>
                <a href="https://twitter.com/AbdulazizGeorge"> 
                    <FontAwesomeIcon className="font-awesome-icon" icon={faTwitter} />
                </a>
                <a href="https://www.linkedin.com/in/george-abdulaziz/"> 
                    <FontAwesomeIcon className="font-awesome-icon" icon={faLinkedin} />
                </a>
                <a href="mailto:georgeabdulaziz1@gmail.com">
                    <FontAwesomeIcon className="font-awesome-icon" icon={faEnvelope} />
                </a>
            </div>
        );
    }
}

export default Icons;